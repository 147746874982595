import Cookies from 'universal-cookie/es6';

const cookies = new Cookies();

export function getBrand() {
  return cookies.get('BRAND');
}

export function setBrand(token) {
  return cookies.set('BRAND', token, { path: '/' });
}

export function removeBrand() {
  return cookies.remove('BRAND');
}

export function getBrandUid() {
  return cookies.get('BRAND_UID');
}

export function setBrandUid(token) {
  return cookies.set('BRAND_UID', token, { path: '/' });
}

export function removeBrandUid() {
  return cookies.remove('BRAND_UID');
}
