/* eslint-disable react/no-danger */
import React, { useReducer, useEffect } from 'react';
import { toastr } from 'utils';
import PropTypes from 'prop-types';

import { productPrice, getProductImage, getVariants, VariantType } from 'utils/products';

import capitalize from 'utils/capitalize';

import * as Ui from './AddProductCard.styles';
import StatusBadge from '../StatusBadge/StatusBadge';
import { ColorPicker } from '../ColorPicker/ColorPicker';
import { SizePicker } from '../SizePicker/SizePicker';

const AddProductCard = ({ lookProduct, parentProduct, onProductAdd, addedProducts = [] }) => {
  const { variants, variantType } = getVariants(parentProduct?.sub_skus);

  const isProductAdded = !!addedProducts.filter(p => p.parent_product === parentProduct.uid).length;

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case 'set':
        return Array.isArray(payload) ? payload : [payload];
      case 'clear':
        return [];
      case 'remove':
        return state.filter(id => id !== payload);
      case 'add':
        return state.concat(payload);

      default:
        throw new Error();
    }
  };

  const [selectedVariants, internalDispatch] = useReducer(reducer, []);

  useEffect(
    () =>
      internalDispatch({
        type: 'set',
        payload:
          parentProduct.sub_skus?.find(s => s.is_primary)?.id ||
          lookProduct?.sub_skus[0]?.id ||
          parentProduct.sub_skus[0]?.id,
      }),
    [parentProduct.sub_skus[0]?.id],
  );

  const onVariantClick = variantId => {
    if (selectedVariants.includes(variantId)) {
      internalDispatch({ type: 'remove', payload: variantId });
    } else {
      internalDispatch({ type: 'add', payload: variantId });
    }
  };

  const handleProductAdd = () => {
    if (!selectedVariants.length) {
      return toastr.error('Product not added', 'Please choose product variant');
    }

    return onProductAdd({
      parent_product: parentProduct.uid,
      name: parentProduct.name,
      sub_skus: parentProduct.sub_skus,
      selected_variants: selectedVariants,
      primary_sku: selectedVariants[0],
      image: parentProduct.sub_skus[0]?.images[0],
    });
  };

  const onSelectAllClick = e => {
    if (e.target.checked) {
      internalDispatch({ type: 'set', payload: variants.map(({ id }) => id) });
    } else internalDispatch({ type: 'clear' });
  };

  const renderSelectAllButton = () => {
    if (variants.length > 1) {
      return (
        <Ui.SelectAll>
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id={parentProduct.name}
            onClick={onSelectAllClick}
          />
          <label className="form-check-label" htmlFor={parentProduct.name}>
            Select all
          </label>
        </Ui.SelectAll>
      );
    }
    return <div />;
  };

  return (
    <Ui.AddProductCard>
      <StatusBadge product={parentProduct} />
      <img alt="product" src={getProductImage(parentProduct.sub_skus)} />
      <div>
        <p
          className="name"
          dangerouslySetInnerHTML={{
            __html: capitalize(parentProduct.name),
          }}
          style={{ minHeight: '30px' }}
        />
        <p
          className="description"
          dangerouslySetInnerHTML={{
            __html: parentProduct.description,
          }}
        />
      </div>
      <div className="d-flex flex-column justify-content-end" style={{ flex: 1 }}>
        <p className="price m-0">{productPrice(parentProduct.sub_skus)}</p>

        <div className="d-flex flex-column">
          {variantType === VariantType.COLOR && (
            <ColorPicker
              colors={variants}
              onClick={variantId => onVariantClick(variantId)}
              selectedColors={selectedVariants}
            />
          )}

          {variantType === VariantType.SIZE && (
            <SizePicker
              sizes={variants}
              onClick={sizeId => onVariantClick(sizeId)}
              selectedSizes={selectedVariants}
            />
          )}
          <div className="d-flex justify-content-between align-items-center">
            {renderSelectAllButton()}
            <Ui.EditButton disabled={isProductAdded} onClick={handleProductAdd}>
              Add
            </Ui.EditButton>
          </div>
        </div>
      </div>
    </Ui.AddProductCard>
  );
};

AddProductCard.propTypes = {
  lookProduct: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    parent: PropTypes.string.isRequired,
    primary_sku: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    sub_skus: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ),
  }),
  parentProduct: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    sub_skus: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        is_primary: PropTypes.bool.isRequired,
        images: PropTypes.arrayOf(PropTypes.string),
      }),
    ).isRequired,
  }),
  onProductAdd: PropTypes.func,
  addedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      parent_product: PropTypes.string,
      sub_skus: PropTypes.string,
      primary_sku: PropTypes.string,
    }),
  ),
};

export default AddProductCard;
