import React from 'react';
import PropTypes from 'prop-types';

export const Spinner = ({ style }) => (
  <div style={style} className="spinner-border text-black" role="status">
    <span className="sr-only" />
  </div>
);

Spinner.propTypes = {
  style: PropTypes.object,
};

export default Spinner;
