import produce from 'immer';
import {
  ADD_FEATURED_ARTIST_SUCCESS,
  DELETE_FEATURED_ARTIST_SUCCESS,
  GET_FEATURED_ARTISTS_SUCCESS,
  GET_FEATURED_ARTISTS_FAILURE,
} from './constants';

export const initialState = {
  fetched: false,
  data: [],
};

const featuredArtistsReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case GET_FEATURED_ARTISTS_SUCCESS: {
        draft.data = payload.artists;
        draft.fetched = true;
        break;
      }
      case GET_FEATURED_ARTISTS_FAILURE: {
        draft.fetched = true;
        break;
      }
      case ADD_FEATURED_ARTIST_SUCCESS: {
        draft.data = draft.data.concat(payload.artist);
        break;
      }
      case DELETE_FEATURED_ARTIST_SUCCESS: {
        draft.data = draft.data.filter(a => a.uid !== payload.uid);
        break;
      }

      default:
        break;
    }
  });

export default featuredArtistsReducer;
