const { isObject } = require('lodash');

const errorMessages = (error, messages = []) => {
  if (isObject(error)) {
    Object.keys(error).forEach(key => {
      if (Array.isArray(error[key])) {
        messages.push(error[key].join(' '));
      }
      if (isObject(error[key])) {
        errorMessages(error[key], messages);
      }
    });
  }
  return messages;
};

export default errorMessages;
