import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const Look = styled.div.attrs({
  className: 'd-flex w-100 align-content-center',
})`
  .form-group {
    margin-bottom: 8px;
    label {
      padding-top: 0 !important;
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

export const PageHeader = styled.div`
  h1 {
    font-size: 30px;
    margin: 0;
  }
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const SectionTitle = styled.label`
  font-weight: bold;
  margin-top: 20px;
`;

export const AddProductsButton = styled(Button).attrs({
  className: 'mb-4',
  variant: 'outline-dark',
  size: 'lg',
})`
  border-radius: 0;
  width: 220px;
`;

export const RowSectionWrapper = styled.div`
  max-width: 400px;
  margin-top: 24px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;

  p {
    margin: 0;
    padding-left: 6px;
  }
`;
