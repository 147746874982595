import produce from 'immer';
import * as types from './constants';

export const initialState = {
  modalId: '',
  modalProps: {
    onClose: undefined,
    description: '',
    heading: '',
    closeLabel: '',
  },
};

const modalReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case types.OPEN_MODAL:
        draft.modalId = payload.modalId;
        draft.modalProps = { ...initialState.modalProps, ...payload.modalProps };
        break;

      case types.CLOSE_MODAL:
        draft.modalId = '';
        draft.modalProps = { ...initialState.modalProps };
        break;

      case types.SET_BUTTON_PROPS:
        draft.modalProps = { ...draft.modalProps, buttonsProps: payload };
        break;

      default:
        return state;
    }
  });

export default modalReducer;
