const getImgFromDataUrl = dataURL =>
  new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.src = dataURL;
  });

const getCroppedImg = async (image, crop, fileName, type) => {
  const orgImg = await getImgFromDataUrl(image.src);
  const canvas = document.createElement('canvas');
  const scaleX = orgImg.naturalWidth / image.width;
  const scaleY = orgImg.naturalHeight / image.height;
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas.getContext('2d');

  // New lines to be added
  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  await ctx.drawImage(
    orgImg,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  // As Base64 string
  // const base64Image = canvas.toDataURL("image/jpeg");
  // return base64Image;

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(
      blob => {
        blob.name = fileName;
        resolve(blob);
      },
      type,
      1,
    );
  });
};

export { getCroppedImg };
