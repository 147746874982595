import styled from 'styled-components';
import { HorizontalList } from '../HorizontalList/HorizontalList';

export const Wrapper = styled(HorizontalList)`
  display: flex;
  gap: 2px;
  text-align: center;
  margin: 0 auto;
  overflow: auto;
  width: 100%;
  padding: 8px 0 12px 0;
  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

export const Size = styled.div`
  box-sizing: border-box;
  min-width: 40%;
  flex-shrink: 0;
  flex: 1;
  padding: 0 4px;
  color: black;
  background: white;
  border: 1px solid black;
  font-size: 12px;
  letter-spacing: 0;
  overflow-x: hidden;
  &.active {
  }
  ${({ selected }) =>
    selected &&
    `
    color: white;
    background: black;
  `};

  p {
    white-space: nowrap;
    margin: 0;
  }
`;

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background: #000000;
`;
