import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 0;
`;

export const ColorsWrapper = styled.div`
  display: flex;
  justify-content: center;
  > div {
    display: flex;
    max-width: 150px;
    margin: 0 5px;
    overflow: hidden;
    border-radius: 20px;
  }
`;

export const Color = styled.button`
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  font-size: 0;
  background-color: ${({ color }) => color};
  ${({ selected }) =>
    selected &&
    `
    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: white;
      border-radius: 8px;
      left: 8px;
      top: 8px;
    }
  `};
`;

export const Arrow = styled.button`
  margin: 0;
  cursor: pointer;
  margin-top: 8px;
  padding: 0;
  outline: 0;
  z-index: 100;
  top: 20px;
  border: solid black;
  border-width: 0 3px 3px 0;
  height: 13px;
  width: 13px;
  min-width: unset;
  min-height: unset;
  background: white;
  transform: ${({ direction }) => (direction === 'left' ? 'rotate(135deg)' : 'rotate(315deg)')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const ColorName = styled.p`
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0 auto;
  font-size: 14px;
  color: ${({ color }) => color};
  margin-top: 7px;
  fontfamily: AnoBold, Helvetica, Arial, sans-serif;
`;
