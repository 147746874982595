import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { throttle } from 'lodash';
import EntityService from 'api/services/EntityService';
import MenuList from './MenuList';

let requestId = 0;

const SelectSalonFilter = ({
  setFilter,
  name,
  column,
  withCaption = false,
  className,
  wrapperStyle,
}) => {
  const [locations, setLocations] = React.useState([]);
  const onInputChange = search => {
    if (!search) {
      return;
    }
    requestId += 1;
    // TODO: error handling
    const fetchLocations = async fetchRequestId => {
      const response = await EntityService.fetch('location', undefined, { search });
      const locationsPage = response?.results || [];
      const locationsOptions = locationsPage.map(({ name: locationName, sap_id }) => ({
        label: `${locationName} - ${sap_id}`,
        value: locationName,
      }));
      if (fetchRequestId !== requestId) {
        // new fetch has been started
        return;
      }
      setLocations(locationsOptions);
    };
    fetchLocations(requestId);
  };
  const onInputChangeThrottled = throttle(onInputChange, 300);

  const onChange = selectedOption => {
    setFilter(column, selectedOption.value || undefined);
  };

  return (
    <Form.Group style={wrapperStyle} className={className}>
      {withCaption && (
        <Form.Label style={{ textTransform: 'capitalize' }}>{name || column}</Form.Label>
      )}

      <Select
        closeMenuOnSelect
        filterOption={() => true} // this makes all the difference - filtering occurs on server side
        options={locations}
        placeholder="type to search"
        isMulti={false}
        onChange={onChange}
        onInputChange={onInputChangeThrottled}
        components={{ MenuList }}
      />
    </Form.Group>
  );
};

SelectSalonFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  column: PropTypes.string.isRequired,
  name: PropTypes.string,
  withCaption: PropTypes.bool,
  className: PropTypes.string,
  wrapperStyle: PropTypes.object,
};

export default SelectSalonFilter;
