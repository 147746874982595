import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import * as Ui from './VariantPicker.styles';

const VariantPicker = ({ selectedVariants, variants, onClick }) => {
  const { t } = useTranslation('app');
  return (
    <div>
      <Ui.SizeWrapper>
        {variants.map(item =>
          item.hexValue ? (
            <Ui.Color
              selected={selectedVariants.includes(item.id)}
              color={item.hexValue}
              onClick={() => onClick(item.id)}
            />
          ) : (
            <Ui.Size selected={selectedVariants.includes(item.id)} onClick={() => onClick(item.id)}>
              {item.variantName}
            </Ui.Size>
          ),
        )}
      </Ui.SizeWrapper>

      <p className="mt-5 mb-0 text-muted text-decoration-underline font-weight-bold">
        {t('variants')}:
      </p>
      <div className="mt-0">
        {selectedVariants.map(selectedId => (
          <Ui.DeleteRow>
            <Col>
              <p>{variants.find(({ id }) => id === selectedId).variantName}</p>
            </Col>
            <Col>
              <Ui.DeleteButton onClick={() => onClick(selectedId)}>Remove </Ui.DeleteButton>
            </Col>
          </Ui.DeleteRow>
        ))}
      </div>
    </div>
  );
};

VariantPicker.propTypes = {
  variants: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    variantName: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
  selectedVariants: PropTypes.arrayOf(PropTypes.string),
};

export { VariantPicker };
