import React from 'react';
import PropTypes from 'prop-types';
import { FaFilter } from 'react-icons/fa';
import { FilterButtonWrapper, FilterName, FilterNumber } from './FilterButton.styles';

export const FilterButton = ({ number }) => (
  <FilterButtonWrapper>
    +
    <FaFilter />
    {!!number && <FilterNumber>{number}</FilterNumber>}
    <FilterName>Filters</FilterName>
  </FilterButtonWrapper>
);

FilterButton.propTypes = { number: PropTypes.number };
