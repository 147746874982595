import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';

const SliderFilter = ({
  setFilter,
  preFilteredRows,
  column,
  filters,
  withCaption = false,
  className,
  wrapperStyle,
}) => {
  const [value, setValue] = React.useState(
    () => filters.find(filter => filter.id === column)?.value,
  );

  const rows = React.useMemo(() => preFilteredRows, [preFilteredRows]);
  const [min, max] = React.useMemo(() => {
    let minimum = rows[0].original[column] || 0;
    let maximum = rows[0].original[column] || 0;

    rows.forEach(row => {
      minimum = Math.min(row.original[column], minimum);
      maximum = Math.max(row.original[column], maximum);
    });
    return [minimum, maximum];
  }, [rows, column]);

  const onChange = e => {
    setValue(parseInt(e.target.value, 10));
    setFilter(column, parseInt(e.target.value, 10));
  };

  const resetValue = () => {
    setValue();
    setFilter(column);
  };

  return (
    <Form.Group style={wrapperStyle} className={`d-flex flex-column ${className}`}>
      {withCaption && (
        <Form.Label style={{ textTransform: 'capitalize' }}>
          {column} {typeof value !== 'undefined' && `(${value})`}
          {typeof value !== 'undefined' && <CloseIcon onClick={() => resetValue()} />}
        </Form.Label>
      )}
      <Form.Control
        type="range"
        placeholder="Enter email"
        min={min}
        max={max}
        value={value || min}
        onChange={onChange}
      />
    </Form.Group>
  );
};

SliderFilter.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
  ).isRequired,
  setFilter: PropTypes.func.isRequired,
  column: PropTypes.string.isRequired,
  preFilteredRows: PropTypes.array.isRequired,
  withCaption: PropTypes.bool,
  className: PropTypes.string,
  wrapperStyle: PropTypes.object,
};

export default SliderFilter;
