import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { entityActions, fetchFlagDown } from 'redux/entities/actions';
import entitySelectors from 'redux/entities/selectors';

export const entityHOC = (ChildComponent, entity, flagDown = false) => {
  class HOComponent extends Component {
    componentDidMount() {
      if (flagDown) {
        this.props.clearFlag();
      }
      const initialFilters = {};
      if (entity === 'retailer') {
        initialFilters.test_user_flag = false;
      }
      this.props.fetchData(initialFilters);
    }

    render() {
      let { data } = this.props;
      const paginationProps = {};

      if (data?.results && typeof data?.count === 'number') {
        paginationProps.dataCount = data?.count || 0;
        data = data?.results || [];
      }
      const childrenProps = {
        [`${entity}s`]: data,
        isDataReady: this.props.fetched,
        ...paginationProps,
      };

      return <ChildComponent {...this.props} {...childrenProps} />;
    }
  }

  const mapStateToProps = createSelector(
    [entitySelectors[`${entity}Selector`], entitySelectors[`${entity}FetchFlagSelector`]],
    (data, fetched) => ({ data, fetched }),
  );

  const mapDispatchToProps = dispatch => ({
    clearFlag: () => dispatch(fetchFlagDown(entity)),
    fetchData: params => dispatch(entityActions.fetchEntity(entity, params)),
  });

  HOComponent.propTypes = {
    clearFlag: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    fetched: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
  };

  HOComponent.displayName = `${entity.toUpperCase()}_ENTITY_PAGE`;

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(HOComponent);
};
