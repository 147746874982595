/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import PropTypes from 'prop-types';

import * as Ui from './SizePicker.styles';

export const SizePicker = ({ onClick, sizes, defaultActive, selectedSizes }) => {
  const travelSize = '- travel size';

  const arrow = direction => (
    <Ui.ArrowWrapper>
      {direction === 'left' ? (
        <MdArrowBack size="16px" color="#ffffff" />
      ) : (
        <MdArrowForward size="16px" color="#ffffff" />
      )}
    </Ui.ArrowWrapper>
  );

  return (
    <Ui.Wrapper
      withArrows={sizes.length > 2}
      autoScroll
      defaultActive={sizes.indexOf(defaultActive)}
      customArrows={{ left: arrow('left'), right: arrow('right') }}
    >
      {sizes.map((size, index) => (
        <Ui.Size
          selected={selectedSizes.includes(size.id)}
          onClick={() => onClick(size.id)}
          key={size.uid}
          data-index={index}
        >
          <p>
            {size.variantName.endsWith(travelSize)
              ? size.variantName.slice(0, -travelSize.length)
              : size.variantName}
          </p>
        </Ui.Size>
      ))}
    </Ui.Wrapper>
  );
};

SizePicker.propTypes = {
  sizes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      variantName: PropTypes.string,
    }),
  ).isRequired,
  selectedSizes: PropTypes.arrayOf(PropTypes.string),
  defaultActive: PropTypes.string,
  onClick: PropTypes.func,
};
