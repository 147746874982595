import styled from 'styled-components';

export const AddedProduct = styled.div`
  display: flex;
  flex: 0 0 33%;
  position: relative;
  .data {
    display: flex;
    padding: 0 12px;
    flex-direction: column;
    justify-content: space-between;
  }

  p {
    margin: 0;
    font-size: 12px;
  }
  .name {
    font-weight: bold;
  }
  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border: 1px solid #e9ecef;
  }
`;

export const Mask = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    text-transform: uppercase;
    text-decoration: underline;
    color: white;
  }
`;
