import moment from 'moment';
import entities from '../../constants/entities';

const entitySelectors = Object.keys(entities).reduce(
  (o, entity) => ({
    ...o,
    [`${entity}Selector`]: state => state.entities[`${entity}`].data,
    [`${entity}FetchFlagSelector`]: state => state.entities[`${entity}`].fetched,
  }),
  {},
);

export const qrCodesSelector = state => state.entities.qr_code.data;

export const lastInventorySynchroSelector = state => {
  const { region } = state.auth.user;
  const regions = state.entities.region.data;
  const userRegion = regions.find(item => item.name === region);
  return moment(userRegion?.last_inventory_synchro).format('DD.MM.YYYY hh:mm') || '';
};

export const getEntityByUid = (entity, uid) => state => {
  if (state.entities[entity]?.data?.results) {
    return state.entities[entity].data.results.find(item => item.uid === uid);
  }

  return state.entities[entity]?.data?.find(item => item.uid === uid);
};

export default entitySelectors;
