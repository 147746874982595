import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card as BootstrapCard } from 'react-bootstrap';
import withDefaultProps from 'helpers/withDefaultProps';
import { ReactComponent as ArrowDown } from '../../assets/svg/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../assets/svg/arrowUp.svg';

import * as Ui from './Card.styles';

const Card = ({
  title,
  subtitle,
  children,
  reverseTitlesPosition = false,
  noPadding = false,
  className,
  toggle = false,
  cardFooter,
  ...restProps
}) => {
  const hasHeader = !!title || !!subtitle;

  const [cardVisible, setCardVisibility] = useState(!toggle);

  const handleHeaderClick = () => {
    if (!toggle) {
      return;
    }

    setCardVisibility(!cardVisible);
  };

  const renderArrows = () => {
    if (!toggle) {
      return null;
    }

    if (cardVisible) {
      return <ArrowUp />;
    }

    return <ArrowDown />;
  };

  return (
    <BootstrapCard {...restProps}>
      {hasHeader && (
        <Ui.Header toggle={toggle} onClick={handleHeaderClick}>
          <div
            className={`d-flex ${reverseTitlesPosition ? 'flex-column-reverse' : 'flex-column'}`}
          >
            {title && <h3 className="m-0">{title}</h3>}
            {subtitle && <h5 className="text-muted">{subtitle}</h5>}
          </div>

          {renderArrows()}
        </Ui.Header>
      )}
      {cardVisible && (
        <BootstrapCard.Body className={className} style={{ ...(noPadding ? { padding: 0 } : {}) }}>
          {children}
        </BootstrapCard.Body>
      )}
      {cardFooter && <div className="d-block text-right card-footer">{cardFooter()}</div>}
    </BootstrapCard>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  reverseTitlesPosition: PropTypes.bool,
  noPadding: PropTypes.bool,
  toggle: PropTypes.bool,
  cardFooter: PropTypes.node,
  ...withDefaultProps,
};

export default Card;
