import styled from 'styled-components/macro';
import { Button } from 'react-bootstrap';

export const ButtonWithIcon = styled(Button)`

  width:100%;
  height: ${({ small }) => (small ? '30px' : '40px')};
  line-height: ${({ small }) => (small ? '30px' : '40px')};
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0,0,0,.3);
  transition: all 0.25s cubic-bezier(0.310, -0.105, 0.430, 1.400);
  outline: none;
  border: none;
  &:disabled {
    opacity: 0.3;
  }
  
  span,
  .icon {
    display: flex;
    height: 100%;
    position: absolute;
    top: 0;
    justify-content: center;
    align-items: center;
  }
  
  span {
    width: 72%;
    line-height: inherit;
    font-size: 0.9375rem;
    white-space: nowrap;
    left: 0;
    transition: all 0.25s cubic-bezier(0.310, -0.105, 0.430, 1.400);
    
    &:after {
      content: '';
      background-color: white;
      opacity: 0.5;
      width: 2px;
      height: 70%;
      position: absolute;
      top: 15%;
      right: -1px;
    }
  }
  
  .icon {
    width: 28%;
    right: 0;
    transition: all 0.25s cubic-bezier(0.310, -0.105, 0.430, 1.400);
    
    .svg-inline--fa {
      color:  ${({ fontColor }) => fontColor};
      font-size: 20px;
      vertical-align: middle;
      transition: all 0.25s cubic-bezier(0.310, -0.105, 0.430, 1.400), height 0.25s ease;
    }
    
    .fa-remove {
      height: 20px;
    }
    
    .fa-check {
      display: none;
    }
  }

  &:enabled {
    &:hover {
    
      span {
        left: -72%;
        opacity: 0;
      }
      
      .icon {
        width: 100%;
        transform: scale(1.4)
  
      }
    }
    
  
    
    &:hover {
      color:  ${({ fontColor }) => fontColor};
      opacity: .9;
    }
  }
 
  
  &:active {
    opacity: 1
`;
