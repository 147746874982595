import { call, put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18n';
import { toastr } from 'utils';

import EntityService from '../../api/services/EntityService';
import {
  addFeaturedArtistSuccess,
  deleteFeaturedArtistSuccess,
  getFeturedArtistFailure,
  getFeturedArtistSuccess,
} from './actions';
import { DELETE_FEATURED_ARTIST, ADD_FEATURED_ARTIST, GET_FEATURED_ARTISTS } from './constants';

function* getFeaturedArtists() {
  try {
    const featuredArtists = yield call([EntityService, 'fetch'], 'featured_artist', undefined);
    yield put(getFeturedArtistSuccess(featuredArtists));
  } catch (error) {
    toastr.error(i18n.t('toastr:error'), i18n.t('pages:featuredArtist.fetchErrorMessage'));

    yield put(getFeturedArtistFailure());
  }
}

function* addFeaturedArtist(action) {
  const {
    payload: { uid },
  } = action;

  try {
    const addedArist = yield call([EntityService, 'update'], 'retailer', uid, {
      is_featured_artist: true,
    });
    yield put(addFeaturedArtistSuccess(addedArist));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('pages:featuredArtist.addErrorMessage'));
  }
}

function* deleteFeaturedArtist(action) {
  const {
    payload: { uid },
  } = action;
  try {
    const deletedArist = yield call([EntityService, 'update'], 'retailer', uid, {
      is_featured_artist: false,
    });
    yield put(deleteFeaturedArtistSuccess(deletedArist.uid));
  } catch (e) {
    toastr.error(i18n.t('toastr:error'), i18n.t('pages:featuredArtist.deleteErrorMessage'));
  }
}

export const featuredArtistsSagas = [
  takeLatest(GET_FEATURED_ARTISTS, getFeaturedArtists),
  takeLatest(ADD_FEATURED_ARTIST, addFeaturedArtist),
  takeLatest(DELETE_FEATURED_ARTIST, deleteFeaturedArtist),
];
