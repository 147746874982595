import React from 'react';
import PropTypes from 'prop-types';

import * as Ui from './LookSlide.styles';

export const LookSlide = ({ look, onClick, widget }) => {
  const getOnClick = onClick ? { onClick: () => onClick(look) } : {};

  return (
    <Ui.Slide widget={widget} role="button" {...getOnClick}>
      <Ui.CoverPhotoWrapper>
        <Ui.Image src={look.pictures[0]} width={300} />
      </Ui.CoverPhotoWrapper>
      <Ui.Name>{look.name}</Ui.Name>
    </Ui.Slide>
  );
};

LookSlide.propTypes = {
  look: PropTypes.shape({
    name: PropTypes.string,
    pictures: PropTypes.arrayOf(PropTypes.string),
  }),
  onClick: PropTypes.func,
  widget: PropTypes.bool,
};
