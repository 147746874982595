import {
  ADD_FEATURED_ARTIST,
  DELETE_FEATURED_ARTIST,
  ADD_FEATURED_ARTIST_SUCCESS,
  GET_FEATURED_ARTISTS,
  GET_FEATURED_ARTISTS_SUCCESS,
  GET_FEATURED_ARTISTS_FAILURE,
  DELETE_FEATURED_ARTIST_SUCCESS,
} from './constants';

export function addFeaturedArtist(data) {
  return {
    type: ADD_FEATURED_ARTIST,
    payload: data,
  };
}

export function addFeaturedArtistSuccess(artist) {
  return {
    type: ADD_FEATURED_ARTIST_SUCCESS,
    payload: {
      artist,
    },
  };
}

export function deleteFeaturedArtist(data) {
  return {
    type: DELETE_FEATURED_ARTIST,
    payload: data,
  };
}

export function deleteFeaturedArtistSuccess(uid) {
  return {
    type: DELETE_FEATURED_ARTIST_SUCCESS,
    payload: { uid },
  };
}

export function getFeturedArtist() {
  return {
    type: GET_FEATURED_ARTISTS,
  };
}

export function getFeturedArtistSuccess(artists) {
  return {
    type: GET_FEATURED_ARTISTS_SUCCESS,
    payload: {
      artists,
    },
  };
}

export function getFeturedArtistFailure() {
  return {
    type: GET_FEATURED_ARTISTS_FAILURE,
  };
}
