import styled from 'styled-components/macro';

export const ToggleButton = styled.div`
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .button {
    width: 60px;
    height: 24px;
    overflow: hidden;
  }

  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }

  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;

    :disabled {
      cursor: none;
    }
  }

  .knobs {
    z-index: 2;
  }

  .layer {
    width: 100%;
    background-color: #fcebeb;
    z-index: 1;
  }

  #checkbox-wrapper {
    perspective: 60px;
    overflow: visible;
  }

  #checkbox-wrapper .knobs:before,
  #checkbox-wrapper .knobs span {
    content: '';
    position: absolute;
    width: 36px;
    height: 24px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 8px;
    padding: 9px 4px;
    border-radius: 100px;
  }

  #checkbox-wrapper .knobs:before {
    background-color: #f44336;
  }

  #checkbox-wrapper .knobs span:before {
    content: 'OFF';
  }

  #checkbox-wrapper .checkbox:checked + .knobs:before,
  #checkbox-wrapper .checkbox:checked + .knobs span {
    left: 28px;
  }

  #checkbox-wrapper .checkbox:checked + .knobs:before {
    background-color: var(--bs-primary);
  }

  #checkbox-wrapper .checkbox:checked + .knobs span:before {
    content: 'ON';
  }

  #checkbox-wrapper .checkbox:checked ~ .layer {
    background-color: #ebf7fc;
  }
`;
