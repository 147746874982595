import { useEffect, useState } from 'react';
import useQueryBrandRegion from 'hooks/useQueryBrandRegion';
import Service from 'api/services/UnauthenticatedService';

const useWidgetFetcher = (widgetType, widgetCount, defaultValues) => {
  const { brand, region } = useQueryBrandRegion(defaultValues);
  const [data, setData] = useState([]);

  const typeToFetch = async () => {
    switch (widgetType) {
      case 'NewLooks':
        return Service.NewLooks(widgetCount, brand, region);
      case 'TrendingLooks':
        return Service.TrendingLooks(widgetCount, brand, region);
      case 'FeaturedArtists':
        return Service.FeaturedArtists(widgetCount, brand, region);
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (!brand || !region) {
        return;
      }

      const fetchedLooks = await typeToFetch();
      setData(fetchedLooks);
    };
    fetch();
  }, [brand, region, widgetCount]);

  return data;
};

export default useWidgetFetcher;
