import styled from 'styled-components';
import CarouselComponent from '@brainhubeu/react-carousel';

const CarouselWrapper = styled.div`
  width: 100%;
  max-width: 800px;
`;

const Carousel = styled(CarouselComponent)`
  position: relative;
  .BrainhubCarouselItem {
    max-height: 400px;

    img {
      max-height: 400px;
    }
  }
`;

const DotsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px 0;
`;

const Dot = styled.div`
  outline: 0;
  padding: 10px;
  border: none;
  opacity: ${props => (props.active ? 1 : 0.3)};
  font-size: 0;
  cursor: pointer;
  -webkit-appearance: none;
  &:before {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    padding: 0;
    border: none;
    background: #000;
  }
`;

const ThumbnailsWrapper = styled.div`
  position: absolute;
  top: 80px;
  left: 25px;
  max-height: 400px;
  overflow: hidden;
`;

const ThumbnailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  overflow: auto;
`;

const Thumbnail = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
  opacity: ${props => (props.active ? 1 : 0.5)};
  &:hover {
    transform: scale(1.1);
    opacity: 1;
  }
`;

export {
  CarouselWrapper,
  Carousel,
  DotsWrapper,
  Dot,
  ThumbnailsWrapper,
  Thumbnail,
  ThumbnailsContainer,
};
