import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
`;

export const RatioShape = styled.div`
  width: 100px;
  background: lightgrey;
  aspect-ratio: ${({ ratio }) => ratio};
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: white;
    font-size: 40px;
  }
`;
