import styled from 'styled-components';
import { media } from 'utils/rwd';

export const Slide = styled.div`
  flex: 0 0 ${s => (s.widget ? 'auto' : '33%')};
  width: ${s => (s.widget ? '60vh' : undefined)};
  padding-right: 10px;
  ${media.lg`
    padding-right: 20px;
  `}
  cursor: pointer;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const Name = styled.p`
  margin: 15px 0 0 0;
  color: white;
  font-size: 22px;
  line-height: 28px;
`;

export const CoverPhotoWrapper = styled.div`
  width: 100%;
  height: auto;
  overflow-y: auto;
  position: relative;
  :before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;
