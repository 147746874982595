import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  .DraftEditor-editorContainer {
    height: 100%;
  }
`;

export const DescriptionMask = styled.div`
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    background-color: #ffffff
    font-size: 0.9375rem;
    font-weight: bold;
    border-radius: 0.375rem;
    min-height: 75px;
    :hover {
        background-color: #e9ecef;
    }

`;
