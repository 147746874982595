import { Button } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';

const avedaColors = {
  white: 'white',
  almostBlack: '#120E02',
  green1: '#007041',
  vermillionRed: '#BC3C3C',
};

export const avedaButton = css`
  font-size: 15px;
  padding: 9px 19px;
  border-radius: 0;
  text-transform: uppercase;
  &:hover {
    opacity: 0.9;
  }
`;

export const avedaPrimary = css`
  ${avedaButton}
  background-color: ${avedaColors.almostBlack};
  color: ${avedaColors.white};
`;

export const avedaPrimaryOutline = css`
  ${avedaButton}
  background-color: ${avedaColors.white};
  color: ${avedaColors.almostBlack};
  border: 1px solid ${avedaColors.almostBlack};

  &:hover {
    background-color: ${avedaColors.almostBlack};
    color: ${avedaColors.white};
  }
`;

export const avedaSecondary = css`
  ${avedaButton}
  background-color: ${avedaColors.green1};
  color: ${avedaColors.white};
  border: 1px solid ${avedaColors.green1};
  &:hover {
    background-color: ${avedaColors.green1};
  }
`;

export const avedaDanger = css`
  ${avedaButton}
  background-color: ${avedaColors.vermillionRed};
  color: ${avedaColors.white};
  border: 1px solid ${avedaColors.vermillionRed};
  &:hover {
    background-color: ${avedaColors.vermillionRed};
  }
`;

export const BootstrapButton = styled(Button)`
  ${switchProp('$type', {
    avedaPrimary,
    avedaPrimaryOutline,
    avedaSecondary,
    avedaDanger,
  })}
`;
