import he from 'he';

const sanitizeHtml = string => {
  if (!string) return '';
  const withoutTags = string.replace(/<[^>]+>/g, '');
  return he.decode(withoutTags);
};

const stripFromTags = string => string.replace(/<[^>]+>/g, '');

export { sanitizeHtml, stripFromTags };
