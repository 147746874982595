import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Prepend, Icon, Input } from './styles';

const Search = ({ value, onChange, placeholder = '', style }) => {
  const [active, setActive] = React.useState(false);

  return (
    <InputGroup active={active} style={{ minWidth: 135, flex: 1, maxWidth: 'unset', ...style }}>
      <Prepend>
        <Icon>
          <i className="fe fe-search" />
        </Icon>
      </Prepend>
      <Input
        type="text"
        value={value || ''}
        onChange={e => {
          onChange(e.target.value);
        }}
        placeholder={placeholder || 'Search'}
        onBlur={() => setActive(false)}
        onFocus={() => setActive(true)}
      />
    </InputGroup>
  );
};

Search.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.object,
};

export default Search;
