import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Form } from 'react-bootstrap';

import { Wrapper } from 'components';
import { useDispatch } from 'react-redux';
import { updateVariant } from 'redux/entities/actions';

const PrimaryVariantCheckbox = ({ variant: { uid, is_primary, is_editable } }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleClick = () => {
    dispatch(updateVariant({ uid, is_primary: !is_primary, base_product: parseInt(id, 10) }));
  };

  return (
    <Wrapper d-flex justify-content-center>
      <Form.Check
        disabled={!is_editable}
        type="checkbox"
        checked={is_primary}
        onClick={handleClick}
      />
    </Wrapper>
  );
};

PrimaryVariantCheckbox.propTypes = {
  variant: PropTypes.shape({
    uid: PropTypes.string,
    is_primary: PropTypes.bool,
    is_editable: PropTypes.bool,
  }),
};

export default PrimaryVariantCheckbox;
