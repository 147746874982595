import React from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { Form } from 'react-bootstrap';

const TextFilter = ({
  setFilter,
  column,
  filters,
  withCaption = false,
  className,
  wrapperStyle,
}) => {
  const [filterValue, setValue] = React.useState(
    () => filters.find(filter => filter.id === column)?.value,
  );

  const onChange = e => {
    setValue(e.target.value);
    setFilter(column, e.target.value);
  };
  return (
    <Form.Group style={wrapperStyle} className={`d-flex flex-column ${className}`}>
      {withCaption && <Form.Label style={{ textTransform: 'capitalize' }}>{column}</Form.Label>}
      <Form.Control value={filterValue || ''} onChange={onChange} />
    </Form.Group>
  );
};

TextFilter.propTypes = {
  filters: arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
  ).isRequired,
  setFilter: PropTypes.func.isRequired,
  column: PropTypes.string.isRequired,
  withCaption: PropTypes.bool,
  className: PropTypes.string,
  wrapperStyle: PropTypes.object,
};

export default TextFilter;
