import styled from 'styled-components/macro';
import { Form, InputGroup as BootstrapInputGroup } from 'react-bootstrap';

export const InputGroup = styled(BootstrapInputGroup)`
  max-width: 250px;
  border: 1px solid #e2ebf6 !important;
  ${({ active }) => active && `border: 1px solid #2c7be5 !important;`}
  border-radius: 0.375rem;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  input {
    border: none !important;
  }
`;

export const Prepend = styled(InputGroup.Prepend)`
  margin-right: -5px;
  span {
    border: none;
    height: 100%;
  }
`;

export const Icon = styled(InputGroup.Text)``;

export const Input = styled(Form.Control)`
  border-left-width: 0;
  padding: 0 0 0 0.5rem !important;
  min-height: 1px !important;
  &:focus {
    border-color: #d2ddec !important;
  }
`;
