import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const NumberRangeFilter = ({
  setFilter,
  column,
  filters,
  withCaption = false,
  className,
  wrapperStyle,
}) => {
  const [minValue, setMinValue] = React.useState(
    () => filters.find(filter => filter.id === column)?.value[0],
  );
  const [maxValue, setMaxValue] = React.useState(
    () => filters.find(filter => filter.id === column)?.value[1],
  );

  return (
    <Form.Group style={wrapperStyle} className={className}>
      {withCaption && <Form.Label style={{ textTransform: 'capitalize' }}>{column}</Form.Label>}
      <div className="d-flex align-items-center">
        <Form.Control
          value={minValue || ''}
          type="number"
          onChange={e => {
            const val = e.target.value;
            setMinValue(parseInt(val, 10));
            setFilter(column, (old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          placeholder="Min"
        />
        <span className="px-2">to</span>
        <Form.Control
          value={maxValue || ''}
          type="number"
          onChange={e => {
            const val = e.target.value;
            setMaxValue(parseInt(val, 10));

            setFilter(column, (old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
          }}
          placeholder="Max"
        />
      </div>
    </Form.Group>
  );
};

NumberRangeFilter.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
  ).isRequired,
  setFilter: PropTypes.func.isRequired,
  column: PropTypes.string.isRequired,
  withCaption: PropTypes.bool,
  className: PropTypes.string,
  wrapperStyle: PropTypes.object,
};

export default NumberRangeFilter;
