import { useEffect, useState } from 'react';
import EntityService from 'api/services/EntityService';

export const useOwnerGroupPageQrCode = vanity => {
  const [qr, setQr] = useState('');

  const fetchShareInfo = async () => {
    const response = await EntityService.generateOwnerGroupPageQrCode(vanity);
    setQr(response.qr);
  };

  useEffect(() => {
    if (vanity) {
      fetchShareInfo();
    }
  }, [vanity]);

  return qr;
};
