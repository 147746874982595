import styled from 'styled-components';

export const ModalContentWrapper = styled.div`
  .flatpickr-input {
    display: none;
  }
  .flatpickr-calendar.inline {
    margin: auto;
  }

  /* .flatpickr-calendar,
  .flatpickr-innerContainer {
    width: 100%;
  } */
`;
