import React from 'react';
import PropTypes from 'prop-types';

import { SketchPicker } from 'react-color';

const ColorPicker = ({ onChange, onHide }) => {
  const [color, setColor] = React.useState('#fff');
  const picker = React.useRef(null);
  const mounted = React.useRef(false);

  const handleClickOutside = event => {
    if (picker && !picker.current.contains(event.target)) {
      onHide();
    }
  };

  React.useEffect(() => {
    if (mounted.current) {
      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
        mounted.current = false;
      };
    }
    mounted.current = true;
  }, [mounted.current]);
  return (
    <div ref={picker}>
      <SketchPicker
        color={color}
        onChange={colorObj => setColor(colorObj.hex)}
        onChangeComplete={colorObj => onChange(colorObj.hex)}
      />
    </div>
  );
};

ColorPicker.propTypes = {
  onChange: PropTypes.func,
  onHide: PropTypes.func,
};

export default ColorPicker;
