import { css } from 'styled-components/macro';

const breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1700,
};

const media = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  accumulator[label].down = (...args) => css`
    @media (max-width: ${breakpoints[label] - 1}px) {
      ${css(...args)};
    }
  `;
  accumulator[label].up = (...args) => css`
    @media (min-width: ${breakpoints[label] + 1}px) {
      ${css(...args)};
    }
  `;

  return accumulator;
}, {});

function matchBreakpoint(breakpoint) {
  return window.matchMedia(`(min-width: ${breakpoints[breakpoint]}px)`).matches;
}

function matchBreakpointDown(breakpoint) {
  return window.matchMedia(`(max-width: ${breakpoints[breakpoint] - 1}px)`).matches;
}

const matchMedia = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => {
    if (matchBreakpoint(label)) {
      return args[0];
    }
    return {};
  };
  return accumulator;
}, {});

const matchMediaDown = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => {
    if (matchBreakpointDown(label)) {
      return args[0];
    }
    return {};
  };
  return accumulator;
}, {});

export { media, breakpoints, matchMedia, matchMediaDown, matchBreakpointDown, matchBreakpoint };
