import EntityService from 'api/services/EntityService';
import { useEffect, useState } from 'react';

export const useTimeZones = uid => {
  const [timeZones, setTimezones] = useState([]);

  useEffect(() => {
    async function fetch() {
      try {
        if (uid) {
          const response = await EntityService.getBundleTimeZones(uid);
          setTimezones(response);
        } else {
          const response = await EntityService.getBrandContentTimeZones();
          setTimezones(response);
        }
      } catch (error) {
        setTimezones([]);
      }
    }
    fetch();
  }, [uid]);

  return timeZones;
};
