export const wrapperStyleObject = {
  width: '100%',
  border: '1px solid #ced4da',
  borderRadius: '0.375rem',
};

export const editorStyleObject = {
  padding: '0 20px',
  height: '100%',
};

export const toolbarSettings = {
  image: {
    className: 'd-none',
    urlEnabled: false,
    uploadEnabled: false,
    uploadCallback: false,
  },
  embedded: {
    className: 'd-none',
  },
  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
  inline: { inDropdown: true },
  list: { inDropdown: true },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  history: { inDropdown: true },
};
