import styled from 'styled-components';

export const FeatureName = styled.p`
  text-decoration: ${({ checked }) => (checked ? 'line-through' : 'none')};
  margin: 0;
`;

export const CheckIcon = styled.i.attrs({ className: 'fe fe-check' })`
  font-size: 20px;
  color: var(--bs-success);
`;

export const XIcon = styled.i.attrs({ className: 'fe fe-x' })`
  font-size: 20px;
  color: var(--bs-danger);
`;
