import { useEffect, useState } from 'react';

const useInfiniteScroll = (allItems, numberOfItemsToShowOnStart = 20) => {
  const [visibleItems, setItems] = useState([]);

  useEffect(() => {
    if (allItems.length) {
      setItems(allItems.slice(0, numberOfItemsToShowOnStart));
    }
  }, [allItems]);

  const loadNextItems = () =>
    setItems([
      ...visibleItems,
      ...allItems.slice(visibleItems.length, visibleItems.length + numberOfItemsToShowOnStart),
    ]);

  return { visibleItems, loadNextItems };
};

export { useInfiniteScroll };
