import React, { useCallback, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'components/Dialog/Dialog';
import { MODAL_SIZE } from 'components/Dialog/constants';
import { closeModalAction } from 'redux/modal/actions';
import { getCurrentModalIdSelector, getModalProps } from 'redux/modal/selectors';

export const NewModal = ({
  modalId,
  onOpen,
  onClose,
  buttonsProps,
  children,
  closeLabel,
  description,
  heading,
  size = MODAL_SIZE.REGULAR,
}) => {
  const dispatch = useDispatch();
  const currentModalId = useSelector(getCurrentModalIdSelector);
  const { buttonsProps: defaultButtonProps } = useSelector(getModalProps);

  const isOpen = currentModalId === modalId;

  const onCloseDialog = useCallback(
    e => {
      if (onClose) {
        onClose(e);
      }
      dispatch(closeModalAction());
    },
    [dispatch, onClose],
  );

  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    }
  }, [isOpen, onOpen]);

  if (!isOpen) return null;

  return (
    <Dialog
      buttonsProps={buttonsProps || [defaultButtonProps]}
      closeLabel={closeLabel || 'Cancel'}
      description={description}
      heading={heading}
      setCloseDialog={onCloseDialog}
      size={size}
    >
      {children}
    </Dialog>
  );
};

NewModal.propTypes = {
  buttonsProps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  closeLabel: PropTypes.string,
  description: PropTypes.string,
  heading: PropTypes.string,
  modalId: PropTypes.string.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  size: PropTypes.oneOf([Object.values(MODAL_SIZE)]),
};
