import React from 'react';
import { Button } from 'react-bootstrap';
import * as qs from 'qs';
import { push } from 'connected-react-router';
import store from '../configureStore';

export const redirectCell = (cell, entityPath) =>
  cell.value?.length ? (
    <Button
      variant="link"
      onClick={() => store.dispatch(push(`${entityPath}?${qs.stringify({ id: cell.value })}`))}
    >
      {cell.value.length}
    </Button>
  ) : (
    <></>
  );

export const redirectCellV4 = (cell, entityPath, queryKey) =>
  cell.value?.length ? (
    <Button
      variant="link"
      onClick={() =>
        store.dispatch(push(`${entityPath}?${qs.stringify({ [queryKey]: cell.row.original.id })}`))
      }
    >
      {cell.value.length}
    </Button>
  ) : (
    <></>
  );
