import styled from 'styled-components';
import { media } from 'utils/rwd';
import ScrollContainer from 'react-indiana-drag-scroll';

export const Ctr = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
`;

export const Wrapper = styled.div`
  background: black;
  padding-top: 10px;
  padding-bottom: 10px;
  width: ${s => (s.widget ? '100vw' : '100%')};
  height: ${s => (s.widget ? '100vh' : undefined)};

  ${media.lg`
    padding-top: 40px;
    padding-bottom: 40px;
  `}
`;

export const Container = styled(Ctr)`
  padding-right: 0;
`;
export const SectionTitle = styled.h1`
  font-size: 40px;
  margin-bottom: 20px;
  color: #fff;
`;

export const CarouselWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row;
  align-content: center;
  position: relative;
  height: 80%;

  .BrainhubCarouselItem {
    align-items: flex-start;
  }

  .BrainhubCarousel__customArrows:nth-child(1) {
    position: absolute;
    left: 0;
    top: calc(50% - 30px);
    z-index: 1;
  }

  .BrainhubCarousel__customArrows:nth-child(3) {
    position: absolute;
    right: 0;
    top: calc(50% - 30px);
    z-index: 1;
  }
`;
export const Carousel = styled(ScrollContainer)`
  display: flex;
  width: 100%;
`;

export const DotsWrapper = styled.div`
  margin-top: 30px;

  ${media.lg`
    margin-top: 40px;
  `}

  .BrainhubCarousel__dot:before {
    width: 6px;
    height: 6px;
    background: white;

    ${media.lg`
      width: 15px;
      height: 15px;
    `}
  }
`;
