import { toastr } from 'utils';

class LocalStorageService {
  saveDraft(draft, user) {
    const drafts = this.getDrafts(user);
    draft.draftNr = drafts.length + 1;
    draft.lastUpdate = Date.now();
    draft.user = user;
    localStorage.setItem('drafts', JSON.stringify([...drafts, draft]));
    toastr.success('Draft', 'Saved');
  }

  getDrafts(user) {
    const allDrafts = localStorage.getItem('drafts')
      ? JSON.parse(localStorage.getItem('drafts'))
      : [];
    return allDrafts.filter(draft => draft.user === user) || [];
  }

  updateDraft(draftNr, user, values) {
    const drafts = this.getDrafts(user);
    const updated = drafts.map(draft => {
      if (draft.draftNr === draftNr && draft.user === user) {
        return values;
      }
      return draft;
    });
    localStorage.setItem('drafts', JSON.stringify(updated));
    toastr.success('Draft', 'Updated');
  }

  deleteDraft(draftNr, user) {
    const drafts = this.getDrafts();
    const updated = drafts.filter(draft => !(draft.draftNr === draftNr && draft.user === user));
    localStorage.setItem('drafts', JSON.stringify(updated));
    toastr.success('Draft', 'Deleted');
  }

  removeDrafts() {
    localStorage.removeItem('drafts');
  }
}

export default new LocalStorageService();
