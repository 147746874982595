import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';

const MenuList = ({ options, children, getValue }) => {
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * 50;

  return (
    <List
      height={270}
      itemCount={children.length}
      itemSize={50}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

MenuList.propTypes = {
  options: PropTypes.array,
  children: PropTypes.array,
  getValue: PropTypes.func,
};

export default MenuList;
