import styled from 'styled-components';
import { Card as BootstrapCard } from 'react-bootstrap';

export const Header = styled(BootstrapCard.Header)`
  height: unset;
  margin: 0.5rem 0;

  ${({ toggle }) =>
    toggle &&
    `
    cursor: pointer
  `}
`;
