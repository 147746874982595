import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { setQr } from 'redux/entities/actions';
import { useDispatch, useSelector } from 'react-redux';
import { qrCodesSelector } from 'redux/entities/selectors';
import LooksService from '../../api/services/LooksService';
import EnitityService from '../../api/services/EntityService';

const getLinks = async (shortId, type) => {
  if (type === 'location') return EnitityService.generateLocationLinks(shortId);
  return LooksService.genereateStoreLinks(shortId);
};

const QrCode = ({ qr = null, shortId, type }) => {
  const [qrCode, setQrCode] = useState(qr);
  const qrs = useSelector(qrCodesSelector);
  const dispatch = useDispatch();

  const getQrCode = async () => {
    const links = await getLinks(shortId, type);
    dispatch(setQr(links.qr, shortId));
    return links.qr;
  };

  useEffect(() => {
    const fetchAsync = async () => {
      const reduxQr = qrs[shortId] || (await getQrCode());
      setQrCode(reduxQr);
    };
    if (qr) return;
    fetchAsync();
  }, []);

  return (
    <a href={`data:image/jpeg;base64,${qrCode}`} download={shortId}>
      <img
        className="w-100"
        style={{ maxWidth: 100 }}
        src={`data:image/jpeg;base64,${qrCode}`}
        alt="qr code"
      />
    </a>
  );
};

QrCode.propTypes = {
  qr: PropTypes.string,
  shortId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default QrCode;
