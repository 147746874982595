import styled from 'styled-components';

export const Badge = styled.span.attrs({
  className: 'badge badge-secondary',
})`
  position: absolute;
  background: #dfe3e6;
  right: 8px;
  top: 8px;
  color: black;
  padding: 4px 16px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  border-radius: 0;
`;
