import React from 'react';

import PropTypes from 'prop-types';
import * as Ui from './CoverPhotoRatio.styles';

const ASPECT_RATIOS = [
  { name: 'Square', value: '1:1', cssValue: 1 / 1 },
  { name: 'Portrait', value: '4:5', cssValue: 4 / 5 },
  { name: 'Landscape', value: '16:9', cssValue: 16 / 9 },
];

const CoverPhotoRatio = ({ onClick }) => (
  <>
    <h2 className="mb-5">Select an image ratio to add a cover photo</h2>
    <Ui.Wrapper>
      {ASPECT_RATIOS.map(({ value, name, cssValue }) => (
        <div className="d-flex flex-column justify-content-between align-self-end align-items-center">
          <Ui.RatioShape onClick={() => onClick(value)} ratio={cssValue} role="button">
            <i className="fe fe-plus" />
          </Ui.RatioShape>
          <p className="mt-3">{name}</p>
        </div>
      ))}
    </Ui.Wrapper>
  </>
);

CoverPhotoRatio.propTypes = {
  onClick: PropTypes.func,
};
export { CoverPhotoRatio };
