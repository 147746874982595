import styled from 'styled-components';

export const Wrapper = styled.div.attrs({
  className: 'd-flex w-100 justify-content-between',
})`
  div {
    gap: 20px;
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }

  button {
    border-radius: 0;
  }
`;
