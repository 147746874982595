import styled from 'styled-components';
import { Image as Img } from 'react-bootstrap';

export const ProductName = styled.h1`
  text-transform: capitalize;
`;

export const Image = styled(Img)`
  max-height: 400px;
  objectfit: contain;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;

  button {
    border-radius: 0;
  }
`;
