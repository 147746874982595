import CoreService from './CoreService';

class UnauthenticatedService extends CoreService {
  constructor(endpoint) {
    super(endpoint);
    this.UnauthenticatedServiceConfig = { requireAuth: false };
  }

  FeaturedArtists(count, brand, region) {
    return this.api.get(`/featured_artists`, {
      ...this.UnauthenticatedServiceConfig,
      headers: {
        region,
        brand,
      },
      params: {
        count,
      },
    });
  }

  NewLooks(count, brand, region) {
    return this.api.get(`/new_looks`, {
      ...this.UnauthenticatedServiceConfig,
      headers: {
        region,
        brand,
      },
      params: {
        count,
      },
    });
  }

  TrendingLooks(count, brand, region) {
    return this.api.get(`/trending_looks`, {
      ...this.UnauthenticatedServiceConfig,
      headers: {
        region,
        brand,
      },
      params: {
        count,
      },
    });
  }
}

export default new UnauthenticatedService('/v3/embedded_widgets');
