import React from 'react';
import withDefaultProps from 'helpers/withDefaultProps';

const Wrapper = ({ children, ...restProps }) => (
  <div className={Object.keys(restProps).join(' ')} style={{ ...(restProps.style || {}) }}>
    {children}
  </div>
);

Wrapper.propTypes = { ...withDefaultProps() };

export default Wrapper;
