/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import * as Ui from './ColorPicker.styles';

export const ColorPicker = ({ onClick, colors, selectedColors }) => {
  const childrenWrapper = useRef(null);
  const [movedFromLeft, setMovedFromLeft] = useState(0);
  const [activeColorIndex, setActiveColorIndex] = useState(0);

  const onArrowClick = arrowType => {
    const moveAbout = arrowType === 'left' ? -30 : 30;
    setMovedFromLeft(movedFromLeft + (arrowType === 'left' ? -1 : 1));
    childrenWrapper.current?.scrollTo({
      left: childrenWrapper.current.scrollLeft + moveAbout,
      behavior: 'smooth',
    });
  };

  const handleColorClick = (index, color) => {
    setActiveColorIndex(index);
    onClick(color.id);
  };

  const isRightArrowVisible = colors.length > 6 && movedFromLeft !== colors.length - 6;

  return (
    <Ui.Wrapper>
      <Ui.ColorsWrapper>
        <Ui.Arrow
          visible={movedFromLeft !== 0}
          direction="left"
          onClick={() => onArrowClick('left')}
        />
        <div ref={childrenWrapper}>
          {colors.map((color, index) => (
            <Ui.Color
              selected={selectedColors.includes(color.id)}
              aria-label="Color variant"
              onClick={() => handleColorClick(index, color)}
              key={color.name}
              color={color.hexValue}
              data-index={index}
            />
          ))}
        </div>
        <Ui.Arrow
          visible={isRightArrowVisible}
          direction="right"
          onClick={() => onArrowClick('right')}
        />
      </Ui.ColorsWrapper>
      <Ui.ColorName color={colors[activeColorIndex]?.hex_value}>
        {colors[activeColorIndex]?.name}
      </Ui.ColorName>
    </Ui.Wrapper>
  );
};

ColorPicker.propTypes = {
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      hex_value: PropTypes.string,
    }),
  ).isRequired,
  selectedColors: PropTypes.arrayOf(PropTypes.string),

  onClick: PropTypes.func,
};
