import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Arrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  padding: 0px;
  ${({ direction }) => `
    ${direction}: 0;
  `}
`;

export { Arrow, Wrapper };
