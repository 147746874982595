/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { WIDGET_TRENDING_LOOKS } from 'constants/routes';
import { LooksCollectionPureComponent } from 'pages/Locations/PageComponents/EditBanners/LooksCollection/LooksCollection';
import useWidgetFetcher from './useWidgetFetcher';

const onLookClick = look => {
  // new tab with google.com
  window.open(look.share_url, '_blank');
};

const TrendingLooks = ({ brand, region, preview, widgetCount }) => {
  const looks = useWidgetFetcher('TrendingLooks', widgetCount, { brand, region });

  return <LooksCollectionPureComponent widget={!preview} looks={looks} onLookClick={onLookClick} />;
};

const iFrameCode = (region, brand, widgetCount) => {
  const src = `${window.location.origin}${WIDGET_TRENDING_LOOKS}?region=${region}&brand=${brand}${
    widgetCount ? `&count=${widgetCount}` : ''
  }`;

  return String.raw`<iframe
  src="${src}"
  title="Trending Looks"
  style="width: 100%; max-width: 1250px; height: 100%; min-height: 400px; border: none;"
></iframe>`;
};

TrendingLooks.propTypes = {
  brand: PropTypes.string,
  region: PropTypes.string,
  preview: PropTypes.bool,
  widgetCount: PropTypes.string,
};

export { iFrameCode };
export default TrendingLooks;
