import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'components/Dialog/Dialog';
import { getCurrentModalIdSelector, getModalProps } from 'redux/modal/selectors';
import { closeModalAction } from 'redux/modal/actions';
import { CONFIRM_MODAL_ID } from './constants';

export const ConfirmModal = () => {
  const dispatch = useDispatch();
  const currentModalId = useSelector(getCurrentModalIdSelector);
  const { onClose, buttonProps, description, heading, closeLabel } = useSelector(getModalProps);

  const isOpen = currentModalId === CONFIRM_MODAL_ID;

  const onCloseDialog = useCallback(
    e => {
      if (onClose) {
        onClose(e);
      }
      dispatch(closeModalAction());
    },
    [dispatch, onClose],
  );

  if (!isOpen) return null;

  return (
    <Dialog
      buttonsProps={[buttonProps].filter(i => !!i)}
      closeLabel={closeLabel || 'Close'}
      description={description}
      heading={heading}
      setCloseDialog={onCloseDialog}
    />
  );
};
