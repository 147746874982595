import * as types from './constants';

export const openModalAction = (modalId, modalProps) => ({
  type: types.OPEN_MODAL,
  payload: {
    modalId,
    modalProps,
  },
});

export const closeModalAction = () => ({
  type: types.CLOSE_MODAL,
});

export const setButtonPropsAction = buttonProps => ({
  type: types.SET_BUTTON_PROPS,
  payload: buttonProps,
});
