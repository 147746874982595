import React from 'react';
import PropTypes from 'prop-types';
import { getProductInventoryLabel } from 'utils/products';
import { Badge } from './StatusBadgeStyled';

const StatusBadge = ({ product }) => {
  const label = getProductInventoryLabel(product);
  if (!label) {
    return null;
  }

  return <Badge>{label}</Badge>;
};

StatusBadge.propTypes = {
  product: PropTypes.shape({
    sub_skus: PropTypes.arrayOf(
      PropTypes.shape({
        is_primary: PropTypes.bool.isRequired,
        inventory: PropTypes.string.isRequired,
      }),
    ).isRequired,
    primary_sku: PropTypes.shape({
      is_primary: PropTypes.bool.isRequired,
      inventory: PropTypes.string.isRequired,
    }),
  }),
};

export default StatusBadge;
