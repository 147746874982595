import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';

import { ReactComponent as CloseModalIcon } from '../../assets/svg/closeModal.svg';
import {
  iconStyle,
  StyledCloseIcon,
  StyledDialog,
  StyledDialogButton,
  StyledDialogButtonContainer,
  StyledDialogButtonWrapper,
  StyledDialogCloseButton,
  StyledDialogContainer,
  StyledDialogContent,
  StyledDialogDescription,
  StyledDialogHeading,
} from './Dialog.styles';
import { MODAL_SIZE } from './constants';

export const Dialog = ({
  buttonsProps = [],
  closeLabel,
  description,
  heading,
  setCloseDialog,
  size,
  children,
}) => {
  const [isFadingOut, setIsFadingOut] = useState(false);
  const wrappedSetCloseDialog = useCallback(
    e => {
      setIsFadingOut(true);
      if (setCloseDialog) {
        setTimeout(() => setCloseDialog(e), 600);
      }
    },
    [setCloseDialog],
  );

  const showCloseLabel = closeLabel;

  return (
    <StyledDialog $isFadingOut={isFadingOut}>
      <StyledDialogContainer $size={size}>
        <StyledCloseIcon onClick={wrappedSetCloseDialog}>
          <CloseModalIcon style={iconStyle} />
        </StyledCloseIcon>
        {heading && <StyledDialogHeading>{heading}</StyledDialogHeading>}
        {description && <StyledDialogDescription>{description}</StyledDialogDescription>}
        {children && <StyledDialogContent>{children}</StyledDialogContent>}

        <StyledDialogButtonContainer>
          {showCloseLabel && (
            <StyledDialogButtonWrapper>
              <StyledDialogCloseButton onClick={wrappedSetCloseDialog} type="button">
                {showCloseLabel}
              </StyledDialogCloseButton>
            </StyledDialogButtonWrapper>
          )}
          {buttonsProps
            .filter(i => !!i)
            .map(({ label, onClick, disabled }) => {
              const wrappedOnClick = e => {
                onClick(e);
                wrappedSetCloseDialog(e);
              };

              return (
                <StyledDialogButtonWrapper key={`StyledDialogButtonWrapper-${label}`}>
                  <StyledDialogButton
                    key={`StyledDialogButton-${label}`}
                    onClick={wrappedOnClick}
                    disabled={disabled}
                  >
                    {label}
                  </StyledDialogButton>
                </StyledDialogButtonWrapper>
              );
            })}
        </StyledDialogButtonContainer>
      </StyledDialogContainer>
    </StyledDialog>
  );
};

Dialog.propTypes = {
  buttonsProps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
  closeLabel: PropTypes.string,
  description: PropTypes.string,
  heading: PropTypes.string,
  setCloseDialog: PropTypes.func.isRequired,
  size: PropTypes.oneOf([Object.values(MODAL_SIZE)]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
