import React from 'react';
import * as Ui from './styles';

export const ToggleButton = props => (
  <Ui.ToggleButton>
    <div className="button r" id="checkbox-wrapper">
      <input type="checkbox" className="checkbox" {...props} />
      <div className="knobs">
        <span />
      </div>
      <div className="layer" />
    </div>
  </Ui.ToggleButton>
);

ToggleButton.propTypes = {};

export default ToggleButton;
