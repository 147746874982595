const defaultUser = {
  email: '',
  is_mac_admin: false,
  is_brand_admin: false,
  is_mac_super_admin: false,
  is_brand_owner: false,
  is_brand_manager: false,
};

class User {
  constructor(user = defaultUser) {
    Object.assign(this, user);
  }
}

export default User;
