import styled from 'styled-components/macro';
import { Form, InputGroup as BootstrapInputGroup } from 'react-bootstrap';

const TableHeader = styled.div`
  display: flex;

  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 2px solid #e9ecef;
`;

const TableRow = styled.tr`
  cursor: ${({ clicable }) => (clicable ? 'pointer' : 'auto')};
`;

const TableTitle = styled.h2`
  flex: 1;
  margin-bottom: 0;
`;

const TableSubtitle = styled.p`
  flex: 1;
  margin-bottom: 0;
  color: #6c757d;
  font-size: 0.8rem;
`;

const InputGroup = styled(BootstrapInputGroup)`
  max-width: 250px;
  height: 40px;
  border: 1px solid #e2ebf6 !important;
  ${({ active }) => active && `border: 1px solid #2c7be5 !important;`}
  border-radius: 0.375rem;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  input {
    border: none !important;
  }
`;

const Prepend = styled(InputGroup.Prepend)`
  margin-right: -5px;
  span {
    border: none;
  }
`;

const Icon = styled(InputGroup.Text)``;

const Input = styled(Form.Control)`
  border-left-width: 0;
  padding: 0 0 0 0.5rem !important;
  min-height: 1px !important;
  &:focus {
    border-color: #d2ddec !important;
  }
`;

export { TableHeader, TableTitle, TableSubtitle, InputGroup, Prepend, Icon, Input, TableRow };
