import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash/fp';

export const locationSelector = state => state.location.selectedLocation;
export const locationBodySelector = state => state.location.body;
export const locationStateSelector = state => state.location;

export const locationsGroupSelector = createSelector(
  locationStateSelector,
  get('locationsGroup'),
);

export const hasLocationsGroupSelector = createSelector(
  locationStateSelector,
  get('locationsGroup'),
  group => Boolean(group) && group?.fetched,
);
