import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out, visibility 0s ease-in-out 100ms`,
  opacity: 0,
  visibility: 'hidden',
  display: 'none',
};

const transitionStyles = {
  entering: { opacity: 1, visibility: 'hidden', display: 'block' },
  entered: { opacity: 1, visibility: 'visible', display: 'block' },
  exiting: { opacity: 0, visibility: 'hidden', display: 'block' },
  exited: { opacity: 0, visibility: 'hidden', display: 'none' },
};

export const SectionToggle = ({ trigger = null, defaultOpen = false, children }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const triggerRef = useRef(null);

  const renderTrigger = () => {
    const triggerProps = {
      key: 'T',
      ref: triggerRef,
    };
    triggerProps.onClick = () => setIsOpen(!isOpen);

    if (typeof trigger === 'function') {
      const comp = trigger(isOpen);
      return !!trigger && React.cloneElement(comp, triggerProps);
    }

    return !!trigger && React.cloneElement(trigger, triggerProps);
  };

  return (
    <>
      {renderTrigger()}
      <Transition in={isOpen} timeout={duration}>
        {state => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    </>
  );
};

SectionToggle.propTypes = {
  children: PropTypes.any,
  trigger: PropTypes.any,
  defaultOpen: PropTypes.bool,
};

export default SectionToggle;
