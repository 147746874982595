import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as qs from 'qs';
import { omit } from 'lodash';

const useUrlQueryParamFilter = (columns, setFilter) => {
  const params = useLocation();
  // queryFilters is only for displaying queryFilters in filters menu
  const [queryFilters, setQueryFilters] = useState({});

  React.useEffect(() => {
    const filteredValues = qs.parse(params.search, { ignoreQueryPrefix: true });
    const columnNames = Object.keys(filteredValues);
    columnNames.forEach(columnName => {
      if (columns.find(column => column.accessor === columnName)) {
        setQueryFilters({ ...filteredValues, [columnName]: filteredValues[columnName] });
      }
    });
  }, []);

  const removeQueryFilter = columnName => {
    setQueryFilters(omit(queryFilters, columnName));
    setFilter(columnName);
  };
  return [queryFilters, removeQueryFilter];
};

export default useUrlQueryParamFilter;
