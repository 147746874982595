import styled from 'styled-components';
import { Button, Row } from 'react-bootstrap';

export const Size = styled(Button).attrs(({ selected }) => ({
  variant: selected ? 'dark' : 'outline-dark',
  size: 'sm',
}))`
  border-radius: 0;
`;

export const Color = styled.button`
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  font-size: 0;
  background-color: ${({ color }) => color};
  ${({ selected }) =>
    selected &&
    `
    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: white;
      border-radius: 8px;
      left: 10px;
      top: 10px;
    }
  `};
`;

export const SizeWrapper = styled.div.attrs({ className: 'pt-4' })`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const DeleteButton = styled(Button).attrs({ variant: 'link', size: 'sm' })`
  border-radius: 0;
`;

export const DeleteRow = styled(Row).attrs({ className: 'pt-2 align-items-center' })`
  p {
    margin: 0;
  }
`;
