export const REDUCER_KEY = 'featuredArtists';

export const GET_FEATURED_ARTISTS = `${REDUCER_KEY}/GET_FEATURED_ARTISTS`;
export const GET_FEATURED_ARTISTS_SUCCESS = `${REDUCER_KEY}/GET_FEATURED_ARTISTS_SUCCESS`;
export const GET_FEATURED_ARTISTS_FAILURE = `${REDUCER_KEY}/GET_FEATURED_ARTISTS_FAILURE`;

export const ADD_FEATURED_ARTIST = `${REDUCER_KEY}/ADD_FEATURED_ARTIST`;
export const ADD_FEATURED_ARTIST_SUCCESS = `${REDUCER_KEY}/ADD_FEATURED_ARTIST_SUCCESS`;

export const DELETE_FEATURED_ARTIST = `${REDUCER_KEY}/DELETE_FEATURED_ARTIST`;
export const DELETE_FEATURED_ARTIST_SUCCESS = `${REDUCER_KEY}/DELETE_FEATURED_ARTIST_SUCCESS`;
