import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { locationsGroupSelector } from 'redux/locations/selectors';
import EntityService from 'api/services/EntityService';

export const useLocationGroupRetailers = () => {
  const locationGroup = useSelector(locationsGroupSelector);

  const [retailers, setRetailers] = useState([]);
  const [fetchedForVanityUrl, setFetchedForVanityUrl] = useState(null);

  useEffect(() => {
    const fetchRetailers = async () => {
      const response = await EntityService.getRetailersForGroupPage(locationGroup.uid);
      setFetchedForVanityUrl(locationGroup.vanity_url);
      setRetailers(response.map(retailer => ({ label: retailer.email, value: retailer.uid })));
    };
    if (locationGroup?.vanity_url && locationGroup?.vanity_url !== fetchedForVanityUrl) {
      fetchRetailers();
    }
  }, [locationGroup]);

  if (locationGroup?.vanity_url) {
    return [
      ...retailers,
      ...locationGroup?.assigned_retailers.map(retailer => ({
        label: retailer.email,
        value: retailer.uid,
      })),
    ];
  }

  return [];
};
