import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import withDefaultProps from 'helpers/withDefaultProps';

const Section = ({ children, title, ...restProps }) => (
  <Card className="my-4" {...restProps}>
    {children}
  </Card>
);

Section.Title = ({ children, ...restProps }) => (
  <h4 {...restProps} style={{ backgroundColor: '#f8f9fa ', margin: 0 }}>
    {children}
  </h4>
);
Section.Subtitle = ({ children, ...restProps }) => (
  <h5 className="text-muted pt-2 m-0" {...restProps}>
    {children}
  </h5>
);
Section.Header = ({ children, ...restProps }) => (
  <Card.Header style={{ backgroundColor: '#f8f9fa', height: 'unset' }} {...restProps}>
    {children}
  </Card.Header>
);

Section.Content = ({ noPadding = false, children, ...restProps }) => (
  <Card.Body style={{ ...(noPadding ? { margin: '-1.5rem' } : {}) }} {...restProps}>
    {children}
  </Card.Body>
);

Section.propTypes = { ...withDefaultProps() };
Section.Title.propTypes = { ...withDefaultProps() };
Section.Subtitle.propTypes = { ...withDefaultProps() };
Section.Header.propTypes = {
  ...withDefaultProps(),
};
Section.Content.propTypes = {
  noPadding: PropTypes.bool,
  ...withDefaultProps(),
};

export default Section;
